.areaForm .forgotPass{
    font-size: 18px;
    font-weight: 500;
    display: block;
    text-align: right;
    position: relative;
    margin-top: 13px;
}
.areaForm .forgotPass::after{
    position: absolute;
    content: '';
    bottom: 1px;
    right: 0;
    width: 215px;
    height: 1px;
    background-color: #040000;
    -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
}
.areaForm .forgotPass:hover{
    color: #040000;
}
.areaForm .forgotPass:hover::after{
    opacity: 0;
    /* background-color: #FFD702; */
}
.areaForm .areaBtn{
    margin-top: 30px;
}